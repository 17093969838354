<template lang="pug">
    .py-4.px-8
        WelcomeMessage(
            class="mt-8 mb-4 border border-grey-4 rounded p-4 grid grid-cols-1 lg:grid-cols-2 flex items-center"
            v-if="!hasData && !loading"
        )
        NoChartDisplay(
            class="mt-8 mb-4 border border-grey-4 rounded p-4 grid grid-cols-1 lg:grid-cols-2 flex items-center"
            v-if="!hasData && !loading"
        )
        // if theres no data please don't show charts
        SurveySummary.mt-8.mb-4.border.border-grey-4.rounded.p-4(
            v-for="formSet in formSetIds"
            :key="`${formSet.formSetId}`"
            :form-set-id="formSet.formSetId"
            :tenant-id="currentTenantId"
            :title="formSet.title"
            @has-data="setHasData"
            @loading="setLoading"
        )
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import SmallTile from '@/components/shared/SmallTile'
import LineChart from '@/components/charts/LineChart'
import Loader from '@/assets/loader.svg'
import Title from '@/components/basic/Title'
import SurveySummary from '@/components/survey-summary/SurveySummary.vue'
import { useLazyQuery } from '@vue/apollo-composable'
import { GET_FORMSETS_FOR_TENANT } from '@/graphql/queries/getFormsForTenant.gql'
import camelcaseKeys from 'camelcase-keys'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { logErrorMessages } from '@vue/apollo-util'
import WelcomeMessage from '@/components/dashboard/WelcomeMessage'
import NoChartDisplay from '@/components/dashboard/NoChartDisplay'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'

const { useActions, useGetters } = createNamespacedHelpers('')

const logError = (error) => {
    console.log('error that happened')
    logErrorMessages(error)
}

export default {
    components: {
        LineChart,
        SmallTile,
        Loader,
        Title,
        SurveySummary,
        WelcomeMessage,
        NoChartDisplay,
    },
    setup() {
        const formSetIds = ref([])
        const hasDataList = ref([])
        // const hasData = ref([])
        const loading = ref(false)
        const tenantDetailsStore = useTenantDetailsStore()
        const { tenantId: currentTenantId, parentTenantId } = storeToRefs(tenantDetailsStore)
        const firstLoad = ref(true)
        const tenantIdCompare = ref({
            tenantId: currentTenantId.value,
            tenantIdCompare: [
                {
                    tenant_id: { _eq: currentTenantId.value },
                },
            ],
        })

        const {
            onResult,
            onError,
            load: loadFormsets,
            refetch: refetchFormsets,
        } = useLazyQuery(GET_FORMSETS_FOR_TENANT, tenantIdCompare)
        onResult(({ data }) => {
            const formSets = camelcaseKeys(data?.formSets || [], {
                deep: true,
            })
            formSetIds.value = formSets.map((fs) => {
                return { formSetId: fs.formSetId, title: fs.name }
            })
        })

        onError(logError)

        const hasData = computed(() => {
            return hasDataList.value.some((v) => v)
        })

        const setHasData = (val) => {
            hasDataList.value.push(val)
        }

        const setLoading = (val) => {
            loading.value = val
        }

        watch([currentTenantId, parentTenantId],
            (nextValue, prevValue) => {
                const [currId, parentId] = nextValue
                if (parentId) {
                    tenantIdCompare.value = {
                        tenantId: currId,
                        tenantIdCompare: [{
                            tenant_id: { _eq: currId },
                        },{
                            tenant_id: { _eq: parentId },
                        }]
                    }
                }
                if (firstLoad.value) {
                    loadFormsets()
                    firstLoad.value = false
                } else {
                    refetchFormsets()
                }
            }
        )

        return {
            formSetIds,
            hasData,
            loading,
            currentTenantId,
            setLoading,
            setHasData,
        }
    },
}
</script>
